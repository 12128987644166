<template>
    <div>
        
            <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field ref="textField"
                        :key="name"
                        v-model="dateFormatted"
                        :label="required ? label + '*' : label"
                        :hide-details="true"
                        append-icon="mdi-calendar"
                        outlined
                        :disabled="disabled"
                        @click:append="menu1 = true"
                        v-bind="attrs"
                        @blur="date = parseDate(dateFormatted)"
                        :error="errors && errors.length > 0"
                        :class="errors && errors.length ? 'hasError' : ''"
                        v-on="on"
                        @keypress="handleChange"
                        @keydown.tab="menu1 = false"
                        :clearable="clearable || false"
                    ></v-text-field>
                    <ErrorMessages :errors="errors"></ErrorMessages>
                </template>
                <v-date-picker
                    v-model="date"
                    no-title
                    :allowed-dates="allowedDatesFn"
                    @input="menu1 = false"
                ></v-date-picker>
            </v-menu>
            
        
    </div>
</template>

<script>
import dateformat from 'dateformat'

export default {
    name: 'DateInput',
    data() { 
        return {
            date: null,
            dateFormatted: null,
            menu1: false,
        }
    },
    props: {
        value: [String, Object],
        rules: String,
        label: String,
        required: Boolean,
        errors: Array,
        clearable: Boolean,
        updateExpirationDate: Boolean,
        module: String,
        endorsementFormat: String,
        name: String,
        disabled: Boolean,
        allowedDates: {
            type: Function,
            default: () => true
        } 
    },
    emits: [
        'change'
    ],
    watch: {
      date () {
        this.dateFormatted = this.formatDate(this.date)
        this.emitValue();
      },
      value: {
          immediate: true,
          handler(newVal) {
              if (newVal) {
                if (this.module === 'endorsementForm' && this.endorsementFormat === 'dateTimeOffset') {
                    const d = this.transformIsoDate(newVal);
                    if (this.date !== d) {
                        this.date = d;
                    }
                } else {
                    const d = new Date(newVal);
                    const dformat = dateformat(d, 'mm/dd/yyyy');
                    this.date = this.parseDate(dformat);
                }
              } else {
                  this.date = null;
              }
          }
          
      }
    },
    computed: {

    },
    methods: {
        transformIsoDate(date) {
            const newData = new Date(date).toISOString()
            const year = newData.slice(0, 4)
            const month = newData.slice(5, 7)
            const day = newData.slice(8, 10)
            return `${year}-${month}-${day}`
        },
        handleChange(e) {
            this.menu1 = false;
            const charCode = (e.which) ? e.which : e.keyCode;
            // only allow number and keypad numbers
            if (!(charCode >= 48 && charCode <= 57)) {            
                e.preventDefault();
                return;
            }
            if(!this.dateFormatted){
                return;
            }
            this.dateFormatted = this.dateFormatted.replace(/[^0-9/]/g, '')
            var numChars = this.dateFormatted.length;
            if(numChars === 2 || numChars === 5){
                this.dateFormatted += '/';
            }
        },
        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate (date) {
            if (!date) return null;
            if (date.split('/').length != 3) {
                this.dateFormatted = null;
                return null;
            }
            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        emitValue() {
            const isValidDate = Date.parse(this.dateFormatted);
            if (isNaN(isValidDate)) {
                this.dateFormatted = null;
            }

            if (this.module === 'endorsementForm' && this.endorsementFormat === 'dateTimeOffset') {
                const date = new Date(this.dateFormatted) // the endorsementRequest data contract expects a date time offset to be sent in the request.
                date.setUTCHours(0,0,0,0);
                let returnDate = date.toISOString();
                this.$emit('change', returnDate) // the submission gated requested is translating the date time to a date time off set so the ui needs to do the same in order to skip back end translation
            } else {
                this.$emit('change', this.dateFormatted)
            }
            
            if (this.updateExpirationDate && this.module) {
                const effectiveDate = new Date(this.dateFormatted)
                const expirationDate = new Date(effectiveDate.setFullYear(effectiveDate.getFullYear() + 1))
                this.$store.commit('updateFormControl', {
                    module: this.module,
                    name: 'ExpirationDate',
                    value: dateformat(expirationDate, 'mm/dd/yyyy')
                })
            }
        },
        allowedDatesFn(val) {
            return this.allowedDates(val);
        }
    }
  }

</script>


<style lang="scss">
    
</style>